import React from 'react';
import { Link } from "gatsby";
import { StaticImage } from 'gatsby-plugin-image';
import "./mensaje.404.component.scss";

export const Mensaje404Component = () => {
  return (
    <>
      <div className='error-container'>
        <div className='error-404'>

          <title>Página no encontrada</title>
          <br />
          <h2>PÁGINA NO ENCONTRADA</h2>
          <br />

          <p>
            Lo sentimos{" "}
            <span role="img" aria-label="Pensive emoji">
              😔
            </span>{" "}
            la página que buscas no pudo ser localizada.
          </p>
          <br />
          <br />

          <Link to="/">
            <div className='return'>
              Regresar al Inicio
            </div>
          </Link>

          <StaticImage
            className="icon-404 transition-opacity"
            src="../../assets/images/background-404.png"
            alt="Imagen sobre el error 404 página no encontrada"
            placeholder="tracedSVG"
          />

        </div>
      </div>
    </>
  )
}



