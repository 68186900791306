import React from "react";
import { graphql } from "gatsby";
import { MainLayout } from "../layout/main/main.layout";
import { Mensaje404Component } from "../components/mensaje.404/mensaje.404.component";

export function Head() {
  return (
    <title>Página no encontrada</title>
  )
}

const NotFoundPage = ({data}: {data: any}) => {
  return (
    <MainLayout siteMetaData={data.site.siteMetadata} path="404">
        <Mensaje404Component/>
    </MainLayout>
  );
}

export default NotFoundPage

export const query = graphql`
  query SiteMetaData404 {
    site {
      siteMetadata {
        menuLinks {
          link
          name
        }
        siteUrl
        title
        url
      }
    }
  }
`
